@tailwind base;
@tailwind components;
@tailwind utilities;

/* ---- Custom styles---- */

root {
    --alert-margin: 100px
}

.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0s .5s, height 0s .5s, opacity .5s;
}

.fadeIn {
    opacity: 1;
    width: 100%;
    height: 100%;
    transition: opacity .5s;
}

.pagination-line {
    width: 12px;
    height: 1px;
    background-color: theme('colors.gray.200');
}

.animation-shake {
    animation: 0.2s shake ease-in-out infinite;
}

@keyframes shake {
    0% {
        margin-left: 0;
    }
    25% {
        margin-left: 0.5rem;
    }
    75% {
        margin-left: -0.5rem;
    }
    100% {
        margin-left: 0;
    }
}

.opacity-animation-out {
    animation: opacity-out .3s ease-out;
}

@keyframes opacity-out {
    0% {
        opacity: .8;
    }
    100% {
        opacity: 0;
    }
}

.enter {
    animation: alert-animation .3s ease-in-out;
}

@keyframes alert-animation {
    0% {
        margin-top: calc(-1 * var(--alert-margin));
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: .8;
    }
}

.visible-horizontal-scrollbar::-webkit-scrollbar {
    height: 8px;
}

.visible-horizontal-scrollbar::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 5px;
}


.visible-horizontal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 5px;
}

.cropper-crop-box, .cropper-view-box {
    border-radius: 50%!important;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.animated-charts-up {
    height: 0;
    transition: height 0.8s ease;
}

.animated-charts-up.loaded {
    height: 100%;
}

.animated-charts-right {
    opacity: 0;
    width: 0;
    transition: width 0.8s ease;
}

.animated-charts-right.loaded {
    opacity: 2;
    width: 100%;
}
.suppl-html * {
    margin: inherit;
}

.suppl-html a {
    color: #608aad;
}

.suppl-html ol,
.suppl-html ul,
.suppl-html menu {
    padding-inline-start: 40px;
    list-style: auto!important;
}

.editor {
    border: 1px solid #e5e7eb;
    -webkit-border-radius: 0 0 7px 7px;
    -moz-border-radius: 0 0 7px 7px;
    border-radius: 0 0 7px 7px;
    overflow: hidden;
    padding: 8px;
}

.toggle-div {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s, height 0.3s ease-in-out;
}

.toggle-div.show {
    opacity: 1;
    height: 100%;
}